@import "variable";

body {
  background-color: $gray1;
  .userDashboardWrapper {
    .userTitleWrapper {
      display: flex;
      justify-content: space-between;
      padding-top: 32px;
      .userSearchAndViewWrapper {
        display: flex;
      }
      .clearSearch {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: underline;
        cursor: pointer;
        color: $primary_blue;
        margin-right: 20px;
      }
    }
    .userListWrapper {
      margin: 36px 0 12px;
      font-size: $font13;
      font-weight: $semiBoldFont;
      color: $gray6;
      padding: 0 16px;
    }
    .userListWrapperFooter {
      margin-bottom: 40px;
    }
    .userListItem {
      padding: 6px 16px;
      background: $white;
      margin-bottom: 4px;
      cursor: pointer;
      @include transitionForAll($time: 0.3s, $transProp: ease-in);
      &:hover {
        box-shadow: 0px 0px 10px rgba(77, 92, 104, 0.15);
      }
      & > div {
        display: flex;
        align-items: center;
        & > div {
          .userName {
            display: flex;
            align-items: center;
            color: $gray10;
            img {
              border-radius: 50%;
              //padding-right: 10px;
              width: 24px;
              height: 24px;
            }
            span {
              white-space: nowrap;
              overflow: hidden;
              padding-left: 10px;
            }
          }
          .userDetail {
            color: $gray7;
            white-space: nowrap;
            overflow: hidden;
            font-size: $font14;
            margin-right: 10px;
          }

          .moreIcon {
            // float: right;
            cursor: pointer !important;
          }
          .disableIcon {
            // float: right;
            cursor: not-allowed !important;
          }
        }
      }
      .userStatus {
        position: relative;
        display: flex;
        align-items: center;

        span {
          display: block;
          padding-left: 20px;
          border-radius: 2px;
          &.success {
            color: $support_green;
            background-color: $success_bg;
            padding: 0 10px 0 30px;
          }
          &.pending {
            color: $support_gold;
            background-color: $gold_bg;
            padding: 0 10px 0 30px;
          }
          &.deactivated {
            color: $support_red;
            background-color: $error_bg;
            padding: 0 10px 0 30px;
          }
        }
        img {
          position: absolute;
          width: 16px;
          height: 16px;
          padding: 0 0 0 10px;
        }
      }
      .disableRow {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}
