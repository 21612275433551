@import "variable";

body {
  background-color: $gray1;
  .dialogWrapper {
    width: 600px;
    .headerTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 24px;
      border-bottom: 1px solid $gray2;
      & img {
        cursor: pointer;
      }
    }
    .formDetail {
      padding: 24px;
      .removePadding {
        padding: 4px !important;
      }
      .formTitle {
        padding: 8px 0;
      }
      .errorFormTitle {
        padding: 8px 0;
        color: $error_red;
        font-weight: $semiBoldFont;
      }
      .inputBlk {
        & input,
        & textarea {
          // border: none;
          &:focus {
            border: 1px solid $primary_blue;
          }
        }
      }
    }
    .buttonWrapper {
      display: flex;
      justify-content: flex-end;
      gap: 16px;
      padding: 32px;
    }
  }
  .projectDashboardWrapper {
    padding-bottom: 40px;
    .projectTitleWrapper {
      display: flex;
      justify-content: space-between;
      padding-top: 32px;
      .projectSearchAndViewWrapper {
        display: flex;
        gap: 44px;
        .clearSearch {
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: underline;
          cursor: pointer;
          color: $primary_blue;
          margin-right: -20px;
        }
        .viewWrapper {
          display: flex;
          align-items: center;
          gap: 16px;
          padding-bottom: 16px;
        }
        & img {
          cursor: pointer;
        }
      }
    }
    .paginationBlk {
      display: flex;
      justify-content: center;
      margin-top: 8px;
    }
    .projectStatusWrapper {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $gray2;
      margin-bottom: 24px;
      // padding-bottom: 8px;
      .tabLink {
        position: relative;
        color: $gray5;
        height: 100%;
        padding: 14px 16px;
        &:hover,
        &.active {
          color: $primary_blue;
          & span {
            position: absolute;
            height: 2px;
            background: $primary_blue;
            border-top-right-radius: 6px;
            border-top-left-radius: 6px;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }
      }

      .statusButtonWrapper {
        display: flex;
        align-items: center;
        // gap: 16px;
        .statusBtn {
          color: $gray5;
          border-radius: 48px;
          padding: 6px 16px;
          transform: translateY(-4px);
          line-height: $lineHeight24;
          &.active {
            color: $primary_blue;
            background-color: $blue1;
          }
        }
        .checkboxWrapper {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          .checkbox {
            position: absolute;
            padding: 0;
            width: 12px;
            height: 12px;
            color: $gray2;
          }
          & :global(.Mui-checked) {
            color: $primary_blue;
          }
          span {
            padding: 0 8px 0 0;
            font-size: $font14;
            font-family: $font_primary;
            line-height: $lineHeight24;
            color: $gray5;
            cursor: pointer;
            &.activeCheckbox {
              color: $primary_blue;
            }
          }
        }
      }
    }
    .disableRow {
      opacity: 0.5;
      cursor: not-allowed !important;
    }
    .projectGridCardContainer {
      // width: 360px;
      border: 1px solid $gray2;
      border-radius: 4px;
      background-color: $white;
      padding: 16px;
      cursor: pointer;
      @include transitionForAll($time: 0.3s, $transProp: ease-in);
      &:hover {
        box-shadow: 0px 0px 10px rgba(77, 92, 104, 0.15);
      }
      .projectOrgWrapper {
        position: relative;
        display: flex;
        margin-bottom: 12px;
        & img {
          width: 36px;
          height: 36px;
          object-fit: cover;
        }
        .projectOrgDetails {
          .orgName {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: $gray10;
          }
          .orgUrl {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: $gray6;
          }
        }
        .scanId {
          float: right;
          color: $gray6;
          .scanIdNum {
            font-weight: $boldFont;
            color: $gray10;
          }
        }
      }
      .orgDescriptionWrapper {
        padding: 16px 0;
        .orgDescription {
          color: $gray9;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      .scanDetailsWrapper {
        padding-bottom: 16px;
        display: flex;
        .scanFileText {
          color: $gray6;
          line-height: $lineHeight20;
        }
        .scanFileNum {
          color: $gray9;
          font-size: $font14;
        }
      }
      .scanWrapper {
        .scanStatusLabel {
          color: $gray6;
          padding-bottom: 8px;
        }
        .scanStatusWrapper {
          display: flex;
          align-items: center;
          height: 40px;
          .scanStatusText {
            padding-left: 12px;
            align-items: center;
            justify-content: center;
          }
          .scanResultWrapper {
            margin-left: auto;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .scoreColor {
              font-size: $font16;
              line-height: $lineHeight20;
            }

            .scoreClolorCritical {
              color: $csvv_status_critical;
            }
            .scoreClolorHigh {
              color: $csvv_status_high;
            }
            .scoreClolorMedium {
              color: $csvv_status_medium;
            }
            .scoreClolorLow {
              color: $csvv_status_low;
            }
            .scoreClolorNone {
              color: $csvv_status_none;
            }
            .scoreNames {
              color: $gray9;
            }
          }
          .scanResultScoreGreen {
            color: $support_green;
          }
          .scanResultScoreRed {
            color: $support_red;
          }
          .scanResultText {
            color: $gray7;
            text-align: right;
          }
        }
      }
    }
    .listCardHeader {
      color: $gray6;
      padding: 0;
      margin: 0;
      width: 100%;
      text-transform: uppercase;
      padding: 4px 0;
      margin-bottom: 10px;
      & > div {
        padding: 0 0 0 16px;
      }
      .projectId {
        position: relative;
        display: flex;
        align-items: center;
        label {
          margin: 0;
        }
        // .checkbox {
        //   position: absolute;
        //   padding: 0;
        //   width: 12px;
        //   height: 12px;
        //   color: $gray2;
        // }
        // & :global(.Mui-checked) {
        //   color: $primary_blue;
        // }
        span {
          padding-left: 30px;
          font-size: $font13;
          font-weight: $semiBoldFont;
          color: $gray6;
          line-height: $lineHeight16;
        }
      }
    }
    .listCardFooter {
      margin-bottom: 40px;
    }
    .projectListCardContainer {
      // border: 1px solid $gray2;
      margin-top: 4px;
      padding: 6px 0;
      // border-radius: 4px;
      background-color: $white;
      .listCardItem {
        padding: 0;
        margin: 0;
        width: 100%;
        & > div {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 0 0 0 16px;
        }
        .projectId {
          position: relative;
          display: flex;
          align-items: center;
          label {
            margin: 0;
          }
          .checkbox {
            position: absolute;
            padding: 0;
            width: 12px;
            height: 12px;
            color: $gray2;
          }
          & :global(.Mui-checked) {
            color: $primary_blue;
          }
          span {
            padding-left: 30px;
            font-size: $font14;
            font-family: $font_primary;
            line-height: $lineHeight24;
            color: $gray9;
          }
        }
        .projectName {
          position: relative;
          font-weight: $semiBoldFont;
          display: flex;
          align-items: center;
          img {
            position: absolute;
            width: 20px;
          }
          span {
            display: block;
            padding-left: 32px;
          }
        }
        .clientProjectView {
          //padding: 0px, 16px;
          text-align: left;
          //padding-left: 70px;
        }
        .othersProjectView {
          padding-left: 16px;
        }
        .scoreClolorCritical {
          font-size: $font16;
          font-weight: $semiBoldFont;
          color: $csvv_status_critical;
        }
        .scoreClolorHigh {
          font-size: $font16;
          font-weight: $semiBoldFont;
          color: $csvv_status_high;
        }
        .scoreClolorMedium {
          font-size: $font16;
          font-weight: $semiBoldFont;
          color: $csvv_status_medium;
        }
        .scoreClolorLow {
          font-size: $font16;
          font-weight: $semiBoldFont;
          color: $csvv_status_low;
        }
        .scoreClolorNone {
          font-size: $font16;
          font-weight: $semiBoldFont;
          color: $csvv_status_none;
        }
        .scoreNames {
          padding-left: 8px;
        }

        .projectStatus {
          position: relative;
          display: flex;
          align-items: center;
          span {
            display: block;
            padding-left: 20px;
            &.success {
              color: $support_green;
            }
            &.pending {
              color: $gray5;
            }
            &.discarded {
              color: $support_red;
            }
            &.progress {
              color: $primary_blue;
            }
            &.none {
              padding: 0;
            }
          }
          img {
            position: absolute;
          }
        }
        .viewDetailLink {
          font-size: $font14;
          color: $primary_blue;
          text-decoration: underline;
          display: flex;
          padding-left: 0px;
        }
      }
      .disableRow {
        opacity: 0.5;
        cursor: not-allowed;
      }
      cursor: pointer;
      @include transitionForAll($time: 0.3s, $transProp: ease-in);
      &:hover {
        box-shadow: 0px 0px 10px rgba(77, 92, 104, 0.15);
      }
    }
  }
  .productStickyHeader {
    position: sticky;
    -webkit-position: sticky;
    top: 106px;
    z-index: 9;
    background-color: $white;
    // background-color: $gray1;
    &.scrollEffect {
      // box-shadow: 0px 12px 36px rgb(0 0 0 / 12%);
      box-shadow: 0px 8px 8px rgb(0 0 0 /4%);
    }
    &.scanDetailNoSticky {
      position: relative;
      top: 0;
      z-index: 8;
      background-color: $gray1;
    }
    .statusButtonWrapper {
      padding: 16px 0;
      display: flex;
      align-items: center;
      gap: 8px;
      .statusBtn {
        color: $gray8;
        border-radius: 48px;
        padding: 6px 16px;
        line-height: $lineHeight24;
        &.active {
          color: $primary_blue;
          background-color: $blue1;
        }
      }
    }
    .headerWrapper {
      width: 100%;
      background-color: $gray1;
      .backWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 24px;
        margin-left: -24px;
        .backBtn {
          cursor: pointer;
          .btnText {
            display: flex;
            padding: 2px 8px 2px 2px;
            color: $gray7;
            border: 1px solid $gray2;
            border-radius: 2px;
            img {
              padding: 4px;
            }
          }
        }
        .closeBtn {
          display: flex;
        }
      }
    }
    .productTitleBlk {
      // padding-bottom: 16px;
      .productTitle {
        padding-top: 16px;
        margin-bottom: 16px;
        color: $gray10;
        .productTitleLink {
          display: inline-flex;
          cursor: pointer;
        }
        span {
          // color: $gray7;
          // font-weight: $boldFont;
          color: $gray5;
        }
      }
      .productHeaderWrapper {
        display: flex;
        justify-content: space-between;
        .productHeaderLeft {
          display: flex;
          gap: 12px;
          width: 50%;
          & img {
            width: 48px;
            height: 48px;
          }
          .subTitle {
            color: $gray7;
          }
          .productProjectName {
            margin-top: 8px;
          }
        }

        .productHeaderRight {
          display: flex;
          justify-content: flex-end;
          gap: 16px;
          width: 50%;
          .researcherDetail {
            line-height: $lineHeight16;
            color: $gray7;
            display: flex;
            align-items: center;
            margin-bottom: 28px;
            padding-right: 40px;
            //border-right: 1px solid $gray2;
            & span {
              display: flex;
              align-items: center;
              color: $gray10;
              & img {
                margin: 0 10px 0 16px;
                border-radius: 50%;
              }
            }
          }
          .productFileId {
            color: $gray7;
            display: inline-block;
            padding-left: 24px;
            span {
              font-size: $font14;
              line-height: $lineHeight24;
              color: $gray9;
            }
          }
          & img {
            height: 24px;
            width: 24px;
            cursor: pointer;
          }
        }
      }
      .productDescription {
        max-width: 50%;
        padding: 12px 0;
        & span {
          color: $primary_blue;
          cursor: pointer;
        }
      }
    }
  }
  .productDetailPageWrapper {
    margin: 20px 0 40px;
    .scanListView {
      .scanListViewTitleWrapper {
        display: flex;
        justify-content: space-between;
        .scanListViewTitle {
          margin-bottom: 20px;
          font-size: $font20;
          & span {
            margin-left: 8px;
            border-radius: 4px;
            padding: 4px 6px;
            background-color: $gray2;
          }
        }
        & button {
          height: 36px;
          margin-bottom: 16px;
        }
        .scanWrapper {
          display: flex;
          align-items: flex-start;
          gap: 16px;
        }
      }
      .scanDetailChartWrapper {
        width: 100%;
        height: 270px;
        border-radius: 4px;
        border: 1px solid $gray2;
        margin: 24px 0;
        background-color: $white;
        .chartHeading {
          padding: 16px;
          display: flex;
          justify-content: space-between;
          .customButtonWrapper {
            display: flex;
            gap: 20px;
          }
        }
        .graphSecurityScoreWrapper {
          .graphHeader {
            color: $gray6;
            padding-bottom: 8px;
            text-transform: uppercase;
          }
          .graphCriticalLow {
            display: flex;
            padding-bottom: 12px;
            .scoreColorCritical {
              color: $black;
              font-size: $font13;
              width: 80px;
              display: flex;
              .graphDot {
                height: 8px;
                width: 8px;
                background-color: $csvv_status_critical;
                border-radius: 50%;
                display: inline-block;
                margin-right: 4px;
                align-self: center;
              }
              .graphScore {
                margin-left: auto;
              }
            }
            .scoreColorLow {
              color: $black;
              font-size: $font13;
              margin-left: 24px;
              width: 80px;
              display: flex;
              .graphDot {
                height: 8px;
                width: 8px;
                background-color: $csvv_status_low;
                border-radius: 50%;
                display: inline-block;
                margin-right: 4px;
                align-self: center;
              }
              .graphScore {
                margin-left: auto;
              }
            }
          }
          .graphHighPass {
            display: flex;
            padding-bottom: 12px;
            .scoreColorHigh {
              color: $black;
              font-size: $font13;
              width: 80px;
              display: flex;
              .graphDot {
                height: 8px;
                width: 8px;
                background-color: $csvv_status_high;
                border-radius: 50%;
                display: inline-block;
                margin-right: 4px;
                align-self: center;
              }
              .graphScore {
                margin-left: auto;
              }
            }
            .scoreColorPass {
              color: $black;
              font-size: $font13;
              margin-left: 24px;
              width: 80px;
              display: flex;
              .graphDot {
                height: 8px;
                width: 8px;
                background-color: $csvv_status_none;
                border-radius: 50%;
                display: inline-block;
                margin-right: 4px;
                align-self: center;
              }
              .graphScore {
                margin-left: auto;
              }
            }
          }
          .graphMediumTested {
            display: flex;
            padding-bottom: 12px;
            .scoreColorMedium {
              color: $black;
              font-size: $font13;
              width: 80px;
              display: flex;
              .graphDot {
                height: 8px;
                width: 8px;
                background-color: $csvv_status_medium;
                border-radius: 50%;
                display: inline-block;
                margin-right: 4px;
                align-self: center;
              }
              .graphScore {
                margin-left: auto;
              }
            }
            .scoreColorUntested {
              color: $black;
              font-size: $font13;
              margin-left: 24px;
              width: 80px;
              display: flex;
              .graphDot {
                height: 8px;
                width: 8px;
                background-color: $black_1;
                border-radius: 50%;
                display: inline-block;
                margin-right: 4px;
                align-self: center;
              }
              .graphScore {
                margin-left: auto;
              }
            }
          }
        }
        .chartDescriptionWrapper {
          height: 180px;
          padding: 40px 0 40px 72px;
          border-left: 1px solid $gray2;
          .paraTitle {
            color: $gray6;
            padding-bottom: 16px;
            text-transform: uppercase;
            &:last-child {
              padding-bottom: 0;
              display: inline-flex;
              align-items: center;
            }
            span {
              padding-left: 8px;
              text-transform: lowercase;
            }
            .copyLink {
              cursor: pointer;
              display: inline-flex;
              color: $primary_blue;
              font-size: $font14;
              text-transform: lowercase;
              padding-left: 2px;
              img {
                padding-right: 2px;
              }
            }
          }
          .securityScore {
            span {
              padding-right: 10px;
            }
            .secureScoreValue {
              color: $gray9;
            }
            .scoreClolorCritical {
              color: $csvv_status_critical;
            }
            .scoreClolorHigh {
              color: $csvv_status_high;
            }
            .scoreClolorMedium {
              color: $csvv_status_medium;
            }
            .scoreClolorLow {
              color: $csvv_status_low;
            }
            .scoreClolorNone {
              color: $csvv_status_none;
            }
            .scoreNames {
              color: $gray9;
              font-size: $font13;
              padding-left: 8px;
            }
          }
        }
      }
      .analysisTitleWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 32px 0 16px;
        .scanListViewTitle {
          & span {
            margin-left: 8px;
            border-radius: 4px;
            padding: 4px 6px;
            background-color: $gray2;
          }
        }
        .analysisTitleRightBlk {
          display: flex;
          align-items: flex-start;
          gap: 16px;
        }
        & button {
          height: 36px;
          margin-bottom: 16px;
        }
      }
      .analysisWrapper {
        .analysisFilterWrapper {
          margin-bottom: 24px;
          .filterBtn {
            border-radius: 0px;
            border: 1px solid $gray2;
            border-right: none;
            color: $gray7;
            font-weight: $regularFont;
            padding: 4px 8px;
            &:first-child {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }
            &:last-child {
              border-right: 1px solid $gray2;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            }
            & span {
              margin-left: 4px;
              padding: 0 8px;
              width: auto;
              border-radius: 12px;
              background-color: $blue2;
            }
          }
          .activeBtn {
            font-weight: $semiBoldFont;
            color: $gray9;
            span {
              background-color: $primary_blue;
              color: $white;
            }
          }
        }
        .analysisHeader {
          padding: 4px 16px;
          margin-bottom: 16px;
          color: $gray7;
        }
        .analysisListBlk {
          margin-bottom: 8px;
          .analysisListOuterBlk {
            border-radius: 4px !important;
            overflow: hidden;
            .analysisListItem {
              height: 40px;
              .riskBlk {
                display: flex;
                align-items: center;
                position: relative;
                & img {
                  // position: absolute;
                  padding: 4px 0;
                  height: 20px;
                }

                .riskText {
                  font-weight: $semiBoldFont;
                  padding: 0 8px 0 20px;
                  border-radius: 2px;
                  &.riskCritical {
                    color: $red_4;
                    background-color: $red_2;
                  }
                  &.riskSuccess {
                    color: $support_green;
                    background-color: $success_bg;
                  }
                }
              }
              .analysisScore {
                display: flex;
                .scoreClolorCritical {
                  font-size: $font16;
                  font-weight: $semiBoldFont;
                  color: $csvv_status_critical;
                  margin-top: 2px;
                }
                .scoreClolorHigh {
                  font-size: $font16;
                  font-weight: $semiBoldFont;
                  color: $csvv_status_high;
                  margin-top: 2px;
                }
                .scoreClolorMedium {
                  font-size: $font16;
                  font-weight: $semiBoldFont;
                  color: $csvv_status_medium;
                  margin-top: 2px;
                }
                .scoreClolorLow {
                  font-size: $font16;
                  font-weight: $semiBoldFont;
                  color: $csvv_status_low;
                  margin-top: 2px;
                }
                .scoreClolorNone {
                  font-size: $font16;
                  font-weight: $semiBoldFont;
                  color: $csvv_status_none;
                  margin-top: 2px;
                }
                .scoreColorCritical {
                  font-size: $font18;
                  font-weight: $semiBoldFont;
                  color: $csvv_status_critical;
                  margin-top: 2px;
                }
                .scoreColorHigh {
                  font-size: $font18;
                  font-weight: $semiBoldFont;
                  color: $csvv_status_high;
                  margin-top: 2px;
                }
                .scoreColorMedium {
                  font-size: $font18;
                  font-weight: $semiBoldFont;
                  color: $csvv_status_medium;
                  margin-top: 2px;
                }
                .scoreColorLow {
                  font-size: $font18;
                  font-weight: $semiBoldFont;
                  color: $csvv_status_low;
                  margin-top: 2px;
                }
                .scoreColorNone {
                  font-size: $font18;
                  font-weight: $semiBoldFont;
                  color: $csvv_status_none;
                  margin-top: 2px;
                }
                .scoreNames {
                  color: $gray9;
                  font-size: $font14;
                  padding-left: 8px;
                  line-height: 20px;
                  margin-top: 2px;
                }
              }
              .findingsCompleted {
                color: $csvv_status_none;
              }
              .findingsInCompleted {
                color: $gray9;
              }
              .viewDetailLink {
                font-size: $font14;
                color: $primary_blue;
                text-decoration: underline;
                display: flex;
                padding-left: 0px;
              }
              .deleteIcon {
                height: 18px;
                padding-left: 18px;
              }
              .statusWrapper {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding-left: 8px;
                .status {
                  display: flex;
                  gap: 4px;
                  .statusImg {
                    padding-right: 4px;
                  }
                  .success {
                    color: $support_green;
                  }
                  .pending {
                    color: $gray5;
                  }
                  .discarded {
                    color: $support_red;
                  }
                  .progress {
                    color: $primary_blue;
                  }
                }
              }
              a {
                display: flex;
                justify-content: center;
              }
            }
            .analysisListItemDetailWrapper {
              background-color: $gray1;
              padding-bottom: 40px;
              max-height: 800px;
              overflow: hidden;
              overflow-y: scroll;
              .VulnerabilityDetailWrapper {
                .reportHeader {
                  display: flex;
                  align-items: center;
                  padding: 8px 0;
                  .riskBlk {
                    padding-left: 8px;
                    display: inline-flex;
                    align-items: center;
                    position: relative;
                    & img {
                      // transform: translateY(2px);
                      padding: 4px;
                      height: 20px;
                    }
                    .riskText {
                      font-weight: $semiBoldFont;
                      padding: 0 8px 0 20px;
                      border-radius: 2px;
                      &.riskCritical {
                        color: $red_4;
                        background-color: $red_2;
                      }
                      &.riskSuccess {
                        color: $support_green;
                        background-color: $success_bg;
                      }
                    }
                  }
                }
                .implicationBlk {
                  padding-bottom: 16px;
                  .implicationTitle {
                    margin-bottom: 4px;
                  }
                }
                .reportStatusBlk {
                  padding-bottom: 24px;
                  .scanTitle {
                    margin-bottom: 12px;
                  }
                  .scanListItemWrapper {
                    padding: 12px 20px;
                    border: 1px solid $gray2;
                    border-radius: 4px;
                    background-color: $white;
                    position: relative;
                    margin-bottom: 6px;
                    .scanStatusBlk {
                      display: flex;
                      span {
                        padding-left: 8px;
                        &.success {
                          color: $support_green;
                        }
                        &.pending {
                          color: $gray5;
                        }
                        &.discarded {
                          color: $support_red;
                        }
                        &.progress {
                          color: $primary_blue;
                        }
                      }
                    }

                    .editScanDetail {
                      position: absolute;
                      top: 16px;
                      right: 16px;
                      cursor: pointer;
                    }
                  }
                }
                .regulatoryBlk {
                  // padding-bottom: 24px;
                  .scanTitle {
                    margin-bottom: 12px;
                  }
                  .regulatoryBody {
                    border: 1px solid $gray2;
                    border-radius: 4px;
                    // padding: 16px;
                    .titleBlk,
                    .metricsBlk,
                    .middleBlk {
                      padding: 16px;
                      border-bottom: 1px solid $gray2;
                      p {
                        padding-bottom: 8px;
                        &:last-child {
                          padding-bottom: 0;
                        }
                      }
                      .headerScore {
                        margin-top: 8px;
                        font-size: $font18;
                        font-weight: $semiBoldFont;
                        padding-right: 4px;
                        .statusNone {
                          color: $csvv_status_none;
                        }
                        .statusLow {
                          color: $csvv_status_low;
                        }
                        .statusMedium {
                          color: $csvv_status_medium;
                        }
                        .statusHigh {
                          color: $csvv_status_high;
                        }
                        .statusCritical {
                          color: $csvv_status_critical;
                        }
                      }
                    }
                    .lastBlk {
                      padding: 16px;
                      .moreBtn {
                        color: $primary_blue;
                        cursor: pointer;
                      }
                    }
                  }
                }

                .descBlk,
                .pocBlk,
                .remediationBlk {
                  margin-top: 24px;
                  .descTitle {
                    margin-bottom: 16px;
                  }
                  .descOuterBlk {
                    border-radius: 4px;
                    border: 1px solid $gray2;
                    padding: 16px;
                    button {
                      padding: 0;
                    }
                  }
                  .markdown {
                    table {
                      font-size: 14px;
                      line-height: 1.7;
                      max-width: 100%;
                      overflow: auto;
                      border: 1px solid #f6f6f6;
                      border-collapse: collapse;
                      border-spacing: 0;
                      box-sizing: border-box;
                    }

                    table th {
                      text-align: center;
                      font-weight: 700;
                      border: 1px solid #efefef;
                      padding: 10px 6px;
                      background-color: #f5f7fa;
                      word-break: break-word;
                    }

                    table td {
                      border: 1px solid #efefef;
                      text-align: left;
                      padding: 10px 15px;
                      word-break: break-word;
                      min-width: 60px;
                    }
                    table tr {
                      border: 1px solid #efefef;
                      display: table-row;
                      vertical-align: inherit;
                    }

                    ul {
                      font-size: 14px;
                      line-height: 28px;
                      padding-left: 16px;
                      display: block;
                      list-style-type: disc;
                      margin-block-start: 1em;
                      margin-block-end: 1em;
                      margin-inline-start: 0px;
                      margin-inline-end: 0px;
                      padding-inline-start: 16px;
                      li {
                        margin-bottom: 8px;
                        line-height: 1.7;
                        display: list-item;
                        text-align: -webkit-match-parent;
                      }
                    }
                    ol {
                      font-size: 14px;
                      line-height: 28px;
                      padding-left: 16px;
                      display: block;
                      list-style-type: decimal;
                      margin-block-start: 1em;
                      margin-block-end: 1em;
                      margin-inline-start: 0px;
                      margin-inline-end: 0px;
                      padding-inline-start: 16px;
                      li {
                        margin-bottom: 8px;
                        line-height: 1.7;
                        display: list-item;
                        text-align: -webkit-match-parent;
                      }
                    }

                    blockquote {
                      position: relative;
                      margin: 16px 0;
                      padding: 16px 8px 16px 30px;
                      background: none repeat scroll 0 0 rgba(102, 128, 153, 0.05);
                      color: #333;
                      border: none;
                      border-left: 10px solid #d6dbdf;
                    }

                    // p {
                    //   font-size: 14px;
                    //   line-height: 1.7;
                    //   margin: 8px 0;
                    //   overflow-x: hidden;
                    // }
                    code {
                      padding: 3px 0;
                      margin: 0;
                      word-break: normal;
                      background-color: #f5f5f5;
                      border-radius: 0;
                      overflow-x: auto;
                      padding-bottom: 4px;
                    }
                    pre {
                      display: block;
                      padding: 20px;
                      line-height: 28px;
                      word-break: break-word;
                      background-color: #f5f5f5;
                      white-space: break-spaces;
                      code {
                        padding: 3px 0;
                        margin: 0;
                        word-break: normal;
                        font-size: 14px;
                        border-radius: 0;
                        overflow-x: auto;
                      }
                    }
                  }
                }
                .descBlk {
                  margin: 0 0 12px;
                }
              }
              .vulnerableDetails {
                padding-left: 20px;
                border-left: 1px dashed $gray2;
                position: relative;
                .dotStatus {
                  position: absolute;
                  left: -6px;
                  top: 6px;
                }
                .collapsibleTextWrapper {
                  padding: 4px 0 24px;
                  .collapsibleButton {
                    color: $primary_blue;
                    cursor: pointer;
                  }
                }
              }
              .riskBlk {
                display: flex;
                align-items: center;
                position: relative;
                & img {
                  // position: absolute;
                  padding: 4px;
                }
                .riskText {
                  font-weight: $semiBoldFont;
                  padding: 0 8px 0 20px;
                  border-radius: 2px;
                  &.riskCritical {
                    color: $red_4;
                    background-color: $red_2;
                  }
                  &.riskSuccess {
                    color: $support_green;
                    background-color: $success_bg;
                  }
                }
              }
              .analysisScore {
                padding-left: 6px;
                color: $gray9;
                font-size: $font13;
                & span {
                  padding-right: 4px;
                  font-size: $font16;
                  font-weight: $semiBoldFont;
                  color: $support_red;
                }
              }
              .statusWrapper {
                padding-left: 12px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                .status {
                  display: flex;
                  .statusImg {
                    padding-right: 4px;
                  }
                  .statusText {
                    color: $support_green;
                  }
                }
                .editImg {
                  padding-right: 12px;
                }
              }
            }
          }
        }
        .projectListCardContainer {
          // border: 1px solid $gray2;
          margin-top: 4px;
          padding: 6px 16px;
          // border-radius: 4px;
          background-color: $white;

          .listCardItem {
            padding: 0;
            margin: 0;
            width: 100%;
            display: flex;
            align-items: center;
            & > div {
              padding: 0;
            }
            .projectId {
              position: relative;
              display: flex;
              align-items: center;
              label {
                margin: 0;
              }
              .checkbox {
                position: absolute;
                padding: 0;
                width: 12px;
                height: 12px;
                color: $gray2;
              }
              & :global(.Mui-checked) {
                color: $primary_blue;
              }
              span {
                padding-left: 30px;
                font-size: $font14;
                font-family: $font_primary;
                line-height: $lineHeight24;
                color: $gray9;
              }
            }
            .projectName {
              position: relative;
              font-weight: $semiBoldFont;
              display: flex;
              align-items: center;
              img {
                position: absolute;
                width: 20px;
              }
              span {
                display: block;
                // padding-left: 32px;
              }
            }
            .riskBlk {
              display: flex;
              align-items: center;
              position: relative;
              & img {
                // position: absolute;
                // padding-top: 24px;
                height: 20px;
              }
              .riskText {
                font-weight: $semiBoldFont;
                padding: 0 8px 0 20px;
                border-radius: 2px;
                &.riskCritical {
                  color: $red_4;
                  background-color: $red_2;
                }
                &.riskSuccess {
                  color: $support_green;
                  background-color: $success_bg;
                }
              }
            }
            .findingsCompleted {
              color: $csvv_status_none;
            }
            .findingsInCompleted {
              color: $gray9;
            }
            .projectStatus {
              position: relative;
              display: flex;
              align-items: center;
              span {
                display: block;
                padding-left: 20px;
                &.success {
                  color: $support_green;
                }
                &.pending {
                  color: $gray5;
                }
                &.discarded {
                  color: $support_red;
                }
                &.progress {
                  color: $primary_blue;
                }
                &.none {
                  padding: 0;
                }
              }
              img {
                position: absolute;
              }
            }
            .viewDetailLink {
              font-size: $font14;
              color: $primary_blue;
              text-decoration: underline;
              display: flex;
              padding-left: 0px;
            }
          }
          cursor: pointer;
          @include transitionForAll($time: 0.3s, $transProp: ease-in);
          &:hover {
            box-shadow: 0px 0px 10px rgba(77, 92, 104, 0.15);
          }
          .disableRow {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }
      .scanListItemHeader {
        .scanTitle {
          padding: 6px 16px;
          text-transform: uppercase;
          line-height: $lineHeight16;
          color: $gray7;
        }
      }
      .scanListItemWrapper {
        padding: 6px 16px;
        background-color: $white;
        position: relative;
        margin-bottom: 6px;
        cursor: pointer;
        @include transitionForAll($time: 0.3s, $transProp: ease-in);
        &:hover {
          box-shadow: 0px 0px 10px rgba(77, 92, 104, 0.15);
        }
        .scanStatusBlk {
          .moreButton {
            color: $primary_blue;
          }

          .scanListStatus {
            position: relative;
            display: flex;
            align-items: center;
            span {
              display: block;
              padding-left: 20px;
              &.success {
                color: $support_green;
              }
              &.pending {
                color: $gray5;
              }
              &.discarded {
                color: $support_red;
              }
              &.progress {
                color: $primary_blue;
              }
            }
            img {
              position: absolute;
            }
          }
          .scanIconBlk {
            display: flex;
            align-items: center;
            img {
              padding-right: 12px;
              // height: 24px;
            }
            span {
              display: flex;
              align-self: center;
            }
          }
          .moreIcon {
            cursor: pointer;
            float: right;
            margin-right: 16px;
          }
        }

        .scanScoreBlk {
          .scanScore {
            // position: relative;
            display: flex;
            align-items: center;
            font-size: $font14;
            line-height: $lineHeight24;
            .scoreClolorCritical {
              font-weight: $semiBoldFont;
              color: $csvv_status_critical;
            }
            .scoreClolorHigh {
              font-weight: $semiBoldFont;
              color: $csvv_status_high;
            }
            .scoreClolorMedium {
              font-weight: $semiBoldFont;
              color: $csvv_status_medium;
            }
            .scoreClolorLow {
              font-weight: $semiBoldFont;
              color: $csvv_status_low;
            }
            .scoreClolorNone {
              font-weight: $semiBoldFont;
              color: $csvv_status_none;
            }
            .scoreNames {
              // position: absolute;
              display: flex;
              justify-content: space-between;
              padding-left: 8px;
              // font-weight: $semiBoldFont;
              font-size: $font14;
            }
          }
        }
        .moveLeft {
          // padding-left: 48px;
        }
        .scanReportBlk {
          display: flex;
          .downloadIcon {
            display: flex;
          }
          .reportPassword {
            display: flex;
            padding-left: 12px;
            span {
              text-transform: lowercase;
            }
            .copyLink {
              cursor: pointer;
              display: inline-flex;
              align-items: center;
              color: $primary_blue;
              font-size: $font14;
              text-transform: lowercase;
              padding-left: 2px;
              img {
                padding-right: 2px;
                height: 16px;
              }
            }
          }
        }
        .editScanDetail {
          position: absolute;
          top: 16px;
          right: 16px;
          cursor: pointer;
        }
        .editScanDetail_align {
          // position: absolute;
          // top: 10px;
          // right: 16px;
          cursor: pointer;
          padding: 4px 0 0 8px;
        }
        .viewDetailLink {
          position: absolute;
          top: 12px;
          right: 52px;
          font-size: $font14;
          color: $primary_blue;
          text-decoration: underline;
          display: flex;
          padding-left: 0px;
        }

        .viewDetailLink_align {
          //position: absolute;
          // top: 8px;
          // right: 45px;
          font-size: $font14;
          color: $primary_blue;
          text-decoration: underline;
          display: flex;
          padding-left: 40px;
        }
        .client_viewDetailLink_align {
          font-size: $font14;
          color: $primary_blue;
          text-decoration: underline;
          display: flex;
        }
      }
      .scanItemWrapper {
        padding: 8px 16px;
        border: 1px solid $gray2;
        border-radius: 4px;
        background-color: $white;
        position: relative;
        margin-bottom: 6px;
        cursor: default;
        &:hover {
          box-shadow: none;
        }
      }
    }
  }
  .paginationBlk {
    display: flex;
    justify-content: center;
  }
  .editScanDetailPageWrapper {
    .editScanTitle {
      padding: 32px 0;
      .backLink {
        color: $gray7;
        display: flex;
        // line-height: $lineHeight16;
        margin-bottom: 10px;
        cursor: pointer;
        padding: 2px 8px 2px 2px;
        border: 1px solid $gray2;
        border-radius: 2px;
        width: fit-content;
        & img {
          transform: rotate(90deg);
          margin-right: 4px;
        }
      }
    }
    .formDetail {
      max-width: 720px;
      .formTitle {
        margin-bottom: 8px;
      }
      .errorFormTitle {
        padding: 8px 0;
        color: $error_red;
        font-weight: $semiBoldFont;
      }
      .buttonWrapper {
        margin-top: 24px;
        padding: 16px 0;
        display: flex;
        border-top: 1px solid $gray2;
        justify-content: flex-end;
        gap: 16px;
      }
    }
  }
  .updateProjectDetailPageWrapper {
    .editScanTitle {
      padding: 32px 0;
      .backLink {
        color: $gray7;
        display: flex;
        // line-height: $lineHeight16;
        margin-bottom: 10px;
        cursor: pointer;
        padding: 2px 8px 2px 2px;
        border: 1px solid $gray2;
        border-radius: 2px;
        width: fit-content;
        & img {
          transform: rotate(90deg);
          margin-right: 4px;
        }
      }
    }
    .formDetail {
      max-width: 720px;
      .formTitle {
        margin-bottom: 8px;
      }
      .errorFormTitle {
        margin-bottom: 8px;
        color: $error_red;
        font-weight: $semiBoldFont;
      }
      .buttonWrapper {
        margin-top: 24px;
        padding: 16px 0;
        display: flex;
        border-top: 1px solid $gray2;
        justify-content: flex-end;
        gap: 16px;
      }
    }
  }
  .addVulnerabilityPageWrapper {
    .editScanTitle {
      padding: 32px 0 16px;
      .backLink {
        color: $gray7;
        display: flex;
        // line-height: $lineHeight16;
        margin-bottom: 10px;
        cursor: pointer;
        padding: 2px 8px 2px 2px;
        border: 1px solid $gray2;
        border-radius: 2px;
        width: fit-content;
        & img {
          transform: rotate(90deg);
          margin-right: 4px;
        }
      }
    }
    .formDetail {
      max-width: 100%;
      background-color: $white;
      padding: 24px;
      margin-bottom: 40px;
      .titleWrapper {
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid $gray2;
        .formTitle {
          margin-bottom: 8px;
        }
        .errorFormTitle {
          margin-bottom: 8px;
          color: $error_red;
          font-weight: $semiBoldFont;
        }
      }
      .updateTitleWrapper {
        padding-bottom: 24px;
        margin-bottom: 20px;
        border-bottom: 1px solid $gray2;
        .updateTitle {
          border-radius: 4px;
          padding: 20px 16px;
          border: 1px solid $gray2;
          .scanTitle {
            color: $gray7;
            span {
              font-size: $font16;
              padding-left: 8px;
              color: $gray9;
            }
          }
          .editIcon {
            cursor: pointer;
            float: right;
            display: flex;
          }
        }
      }
      .metricsScore {
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid $gray2;
        position: relative;
        .metricsHeader {
          display: flex;
          align-items: center;
          gap: 12px;
          margin-bottom: 24px;
          .headerScore {
            margin-top: 8px;
            font-size: $font18;
            font-weight: $semiBoldFont;
            padding-right: 4px;
            .statusNone {
              color: $csvv_status_none;
            }
            .statusLow {
              color: $csvv_status_low;
            }
            .statusMedium {
              color: $csvv_status_medium;
            }
            .statusHigh {
              color: $csvv_status_high;
            }
            .statusCritical {
              color: $csvv_status_critical;
            }
          }
          .previousStatus {
            color: $gray7;
            align-self: flex-end;
            .statusScore {
              padding-left: 8px;
              line-height: $lineHeight24;
            }
            .statusNone {
              color: $csvv_status_none;
            }
            .statusLow {
              color: $csvv_status_low;
            }
            .statusMedium {
              color: $csvv_status_medium;
            }
            .statusHigh {
              color: $csvv_status_high;
            }
            .statusCritical {
              color: $csvv_status_critical;
            }
          }
        }
        .metricsTitle {
          margin-bottom: 8px;
        }
        & :global(.MuiToggleButtonGroup-root) {
          gap: 16px;
          & :global(button) {
            font-weight: $regularFont;
            color: $gray9;
            padding: 6px 12px;
            text-transform: capitalize;
            border: 1px solid $gray3;
            border-radius: 4px;
            background-color: $white;
          }
          & :global(.Mui-selected) {
            border: 1px solid $blue5;
            background-color: $blue1;
            color: $primary_blue;
          }
        }
      }
      .riskWrapper {
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid $gray2;
        .formTitle {
          margin-bottom: 8px;
        }
        .errorFormTitle {
          margin-bottom: 8px;
          color: $error_red;
          font-weight: $semiBoldFont;
        }
      }
      .editorWrapper {
        .formTitle {
          margin-bottom: 8px;
        }
        .findingListWrapper {
          padding: 12px 0;
          .findingHeaderWrapper {
            margin-bottom: 12px;
            padding: 4px 12px;
            .title {
              color: $gray7;
              text-transform: capitalize;
            }
            .clearBtn {
              padding: 0;
              text-decoration: underline;
              float: right;
            }
          }
          .findingListItemWrapper {
            padding: 6px 12px;
            margin-bottom: 8px;
            border-radius: 4px;
            background-color: $gray1;
            .findingTextOverflow {
              // white-space: nowrap;
              // overflow: hidden;
              // text-overflow: ellipsis;
              padding-right: 12px;
              cursor: pointer;
            }
            .findingAttachment {
              display: flex;
              .attachmentNumber {
                margin-left: 6px;
                padding: 0 8px;
                border-radius: 10px;
                background-color: $blue2;
              }
            }
            .deleteBtn {
              display: flex;
              cursor: pointer;
            }
          }
        }
        .inputBlk {
          input {
            border-radius: 0px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            border: 1px solid $gray2;
            border-bottom: none;
          }
        }
        .inputEditor {
          resize: none;
          width: 100%;
          font-family: "Source Sans Pro", sans-serif;
          font-size: 16px;
          font-weight: 400;
          color: #353941;
          line-height: 20px;
          background: transparent;
          //border-radius: 4px;
          // border: 1px solid #cccdd0;
          padding: 6px 16px;
          box-sizing: border-box;
          border-top: "1px solid #dddcc0" !important;
          border-left: "1px solid #dddcc0" !important;
          border-right: "1px solid #dddcc0" !important;
          border-bottom-left-radius: "0px" !important;
          border-bottom-right-radius: "0px" !important;
        }
        .editorBlk {
          position: relative;
          margin-bottom: 16px;
          .saveBtn {
            position: absolute;
            right: 20px;
            bottom: 4px;
          }
        }
        .openEditorBtn {
          display: flex;
          color: $gray9;
          width: 100%;
          border: 1px solid $gray3;
          &:hover {
            background: none;
          }
        }
        .previousTitle {
          padding: 16px 0;
        }
        .previousFindingOuterBlk {
          position: relative;
          padding-bottom: 32px;
          border-bottom: 1px solid $gray2;
          .previousFindingInnerBlk {
            padding-left: 16px;
            border-left: 1px dotted $gray2;
            padding-bottom: 16px;
            position: relative;
            .dotStatus {
              position: absolute;
              left: -6px;
              top: 6px;
            }
            .previousFindingHeadingBlk {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            .findingDes {
              padding: 8px 0;
              position: relative;
              span {
                cursor: pointer;
                color: $primary_blue;
              }
            }
            .attachmentDoc {
              display: flex;
              .attachmentNumber {
                margin-left: 6px;
                padding: 0 8px;
                border-radius: 10px;
                background-color: $blue2;
              }
            }
          }
        }
      }
      .statusMarkBtn {
        .statusTitle {
          padding: 16px 0 16px;
        }
        .statusSection {
          display: flex;
          justify-content: space-between;
          .statusTextInProgress {
            background-color: $blue1;
            color: $primary_blue;
            border-radius: 4px;
            padding: 8px 12px;
            display: flex;
            & img {
              padding-right: 10px;
            }
          }
          .statusTextCompleted {
            background-color: $success_bg;
            color: $support_green;
            border-radius: 4px;
            padding: 8px 12px;
            display: flex;
            & img {
              padding-right: 10px;
            }
          }
          .rightBlk {
            display: flex;
            //align-items: flex-end;
          }
        }
      }
      .buttonWrapper {
        margin-top: 24px;
        padding: 16px 0;
        display: flex;
        border-top: 1px solid $gray2;
        justify-content: flex-end;
        gap: 16px;
      }
    }
  }
  .reportListWrapper {
    color: $gray6;
    padding-top: 0;
    margin: 0;
    width: 100%;
    text-transform: uppercase;
    padding: 4px 0;
    & > div {
      padding: 0;
    }
  }
  .reportListCardContainer {
    // border: 1px solid $gray2;
    margin-top: 4px;
    padding: 8px 0;
    // border-radius: 4px;
    background-color: $white;
    cursor: pointer;
    @include transitionForAll($time: 0.3s, $transProp: ease-in);
    &:hover {
      box-shadow: 0px 0px 10px rgba(77, 92, 104, 0.15);
    }
    .success {
      color: $support_green;
      margin-left: 4px;
    }
    .pending {
      color: $gray5;
      margin-left: 4px;
    }
    .discarded {
      color: $support_red;
      margin-left: 4px;
    }
    .progress {
      color: $primary_blue;
      margin-left: 4px;
    }
    .reportListItem {
      padding: 0;
      margin: 0;
      width: 100%;
      & > div {
        padding: 0 0 0 16px;
      }

      .reportDetails {
        display: flex;
        justify-content: space-between;
        .downloadIcon {
          padding: 0px 20px;
          margin-left: 50px;
        }
        .deleteIcon {
          padding: 0 20px;
        }
        .viewDetail {
          font-size: $font14;
          color: $primary_blue;
          text-decoration: underline;
          padding: 0 20px;
        }
      }
    }
  }
  .clientVulnerabilityDetail {
    background-color: $white;
    .userHeaderBlk {
      background-color: $gray1;
      padding: 8px 0;
      .containerBlk {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .headerLeftBlk {
          .backBtn {
            border: 1px solid $gray2;
            border-radius: 3px;
            padding: 2px 8px 2px 2px;
            color: $gray7;
            display: flex;
            align-items: center;
            img {
              padding: 4px;
            }
          }
        }
        .headerRightBlk {
          cursor: pointer;
          display: flex;
        }
      }
    }
    .VulnerabilityReportWrapper {
      padding-bottom: 40px;
      .reportHeader {
        display: flex;
        align-items: center;
        padding: 24px 0;
        .riskBlk {
          padding-left: 8px;
          display: inline-flex;
          align-items: center;
          position: relative;
          & img {
            transform: translateY(4px);
            padding: 4px;
            height: 20px;
          }
          .riskText {
            font-weight: $semiBoldFont;
            padding: 0 8px 0 20px;
            border-radius: 2px;
            &.riskCritical {
              color: $red_4;
              background-color: $red_2;
            }
            &.riskSuccess {
              color: $support_green;
              background-color: $success_bg;
            }
          }
        }
      }
      .descriptionBlk {
        padding-bottom: 36px;
        .descriptionTitle {
          margin-bottom: 16px;
        }
      }
      .implicationBlk {
        padding-bottom: 16px;
        .implicationTitle {
          margin-bottom: 16px;
        }
      }
      .reportStatusBlk {
        padding-bottom: 24px;
        .scanTitle {
          margin-bottom: 12px;
        }
        .scanListItemWrapper {
          padding: 12px 20px;
          border: 1px solid $gray2;
          border-radius: 4px;
          background-color: $white;
          position: relative;
          margin-bottom: 6px;
          .scanStatusBlk {
            .scanListStatus {
              position: relative;
              display: flex;
              align-items: center;
              span {
                display: block;
                padding-left: 20px;
                &.success {
                  color: $support_green;
                }
                &.pending {
                  color: $gray5;
                }
                &.discarded {
                  color: $support_red;
                }
                &.progress {
                  color: $primary_blue;
                }
              }
              img {
                position: absolute;
              }
            }
            .scanIconBlk {
              display: flex;
              align-items: center;
              img {
                padding-right: 12px;
                //height: 32px;
              }
              span {
                display: flex;
                align-self: center;
              }
            }
          }

          .scanScoreBlk {
            .scanScore {
              position: relative;
              display: flex;
              align-items: center;
              font-size: $font16;
              line-height: $lineHeight24;
              .scoreClolorCritical {
                font-weight: $semiBoldFont;
                color: $csvv_status_critical;
              }
              .scoreClolorHigh {
                font-weight: $semiBoldFont;
                color: $csvv_status_high;
              }
              .scoreClolorMedium {
                font-weight: $semiBoldFont;
                color: $csvv_status_medium;
              }
              .scoreClolorLow {
                font-weight: $semiBoldFont;
                color: $csvv_status_low;
              }
              .scoreClolorNone {
                font-weight: $semiBoldFont;
                color: $csvv_status_none;
              }
              .scoreNames {
                position: absolute;
                padding-left: 12px;
                // font-weight: $semiBoldFont;
                font-size: $font14;
              }
            }
          }

          .scanReportBlk {
            display: flex;
            .downloadIcon {
              display: flex;
            }
            .reportPassword {
              display: flex;
              padding-left: 12px;
              span {
                text-transform: lowercase;
              }
              .copyLink {
                cursor: pointer;
                display: inline-flex;
                align-items: center;
                color: $primary_blue;
                font-size: $font14;
                text-transform: lowercase;
                padding-left: 2px;
                img {
                  padding-right: 2px;
                  height: 16px;
                }
              }
            }
          }
          .editScanDetail {
            position: absolute;
            top: 16px;
            right: 16px;
            cursor: pointer;
          }
          .viewDetailLink {
            position: absolute;
            top: 12px;
            right: 52px;
            font-size: $font14;
            color: $primary_blue;
            text-decoration: underline;
            display: flex;
            padding-left: 0px;
          }
        }
        // .scanListItemWrapper {
        //   padding: 12px 20px;
        //   border: 1px solid $gray2;
        //   border-radius: 4px;
        //   background-color: $white;
        //   position: relative;
        //   margin-bottom: 6px;
        //   .scanStatusBlk {
        //     display: flex;
        //     span {
        //       padding-left: 8px;
        //       &.success {
        //         color: $support_green;
        //       }
        //       &.pending {
        //         color: $gray5;
        //       }
        //       &.discarded {
        //         color: $support_red;
        //       }
        //       &.progress {
        //         color: $primary_blue;
        //       }
        //     }
        //   }
        //   // }

        //   .editScanDetail {
        //     position: absolute;
        //     top: 16px;
        //     right: 16px;
        //     cursor: pointer;
        //   }
        // }
      }
      .regulatoryBlk {
        // padding-bottom: 24px;
        .scanTitle {
          margin-bottom: 12px;
        }
        .regulatoryBody {
          border: 1px solid $gray2;
          border-radius: 4px;
          // padding: 16px;
          .titleBlk,
          .metricsBlk,
          .middleBlk {
            padding: 16px;
            border-bottom: 1px solid $gray2;
            p {
              padding-bottom: 8px;
              &:last-child {
                padding-bottom: 0;
              }
            }
            .headerScore {
              margin-top: 8px;
              font-size: $font18;
              font-weight: $semiBoldFont;
              padding-right: 4px;
              .statusNone {
                color: $csvv_status_none;
              }
              .statusLow {
                color: $csvv_status_low;
              }
              .statusMedium {
                color: $csvv_status_medium;
              }
              .statusHigh {
                color: $csvv_status_high;
              }
              .statusCritical {
                color: $csvv_status_critical;
              }
            }
          }
          .lastBlk {
            padding: 16px;
            .moreBtn {
              color: $primary_blue;
              cursor: pointer;
            }
          }
        }
      }

      .descBlk,
      .pocBlk,
      .remediationBlk {
        margin-top: 24px;
        .descTitle {
          margin-bottom: 16px;
        }
        .descOuterBlk {
          border-radius: 4px;
          border: 1px solid $gray2;
          padding: 16px;
          button {
            padding: 0;
          }
        }
        .markdown {
          table {
            font-size: 14px;
            line-height: 1.7;
            max-width: 100%;
            overflow: auto;
            border: 1px solid #f6f6f6;
            border-collapse: collapse;
            border-spacing: 0;
            box-sizing: border-box;
          }

          table th {
            text-align: center;
            font-weight: 700;
            border: 1px solid #efefef;
            padding: 10px 6px;
            background-color: #f5f7fa;
            word-break: break-word;
          }

          table td {
            border: 1px solid #efefef;
            text-align: left;
            padding: 10px 15px;
            word-break: break-word;
            min-width: 60px;
          }
          table tr {
            border: 1px solid #efefef;
            display: table-row;
            vertical-align: inherit;
          }

          ul {
            font-size: 14px;
            line-height: 28px;
            padding-left: 16px;
            display: block;
            list-style-type: disc;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            padding-inline-start: 16px;
            li {
              margin-bottom: 8px;
              line-height: 1.7;
              display: list-item;
              text-align: -webkit-match-parent;
            }
          }
          ol {
            font-size: 14px;
            line-height: 28px;
            padding-left: 16px;
            display: block;
            list-style-type: decimal;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            padding-inline-start: 16px;
            li {
              margin-bottom: 8px;
              line-height: 1.7;
              display: list-item;
              text-align: -webkit-match-parent;
            }
          }

          blockquote {
            position: relative;
            margin: 16px 0;
            padding: 16px 8px 16px 30px;
            background: none repeat scroll 0 0 rgba(102, 128, 153, 0.05);
            color: #333;
            border: none;
            border-left: 10px solid #d6dbdf;
          }

          // p {
          //   font-size: 14px;
          //   line-height: 1.7;
          //   margin: 8px 0;
          //   overflow-x: hidden;
          // }

          code {
            padding: 3px 0;
            margin: 0;
            word-break: normal;
            background-color: #f5f5f5;
            border-radius: 0;
            overflow-x: auto;
            padding-bottom: 4px;
            white-space: break-spaces;
          }
        }
      }
      .descBlk {
        margin: 0 0 12px;
      }
    }
  }
}
