@import "variable";

body {
  background-color: $gray1;
  .vulnerabilityDashboardWrapper {
    margin-bottom: 40px;
    .vulnerabilityTitleWrapper {
      display: flex;
      justify-content: space-between;
      padding-top: 32px;
      margin-bottom: 28px;
      span {
        color: $gray9;
        background-color: $gray2;
        font-size: $font20;
        border-radius: 4px;
        padding: 0px 8px;
        margin-left: 10px;
      }
      .vulnerabilitySearchAndViewWrapper {
        display: flex;
        align-items: center;
        gap: 24px;
        .clearSearch {
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: underline;
          cursor: pointer;
          color: $primary_blue;
          margin-left: auto;
          margin-right: 0px;
          background-color: $gray1;
          font-size: $font14;
          margin-top: 14px;
        }
        .vulnerabilityAddNewWrapper {
          position: relative;
          .moreIcon {
            opacity: 0;
            position: absolute;
            right: 0;
            padding: 0 25px;
            height: 100%;
            cursor: pointer;
          }
        }
      }
    }
    .vulnerabilityHeader {
      margin-bottom: 12px;
      // display: flex;
      // justify-content: space-between;
      padding: 0 30px 0 16px;
      .vulnerabilityText {
        color: $gray6;
        font-weight: $semiBoldFont;
      }
      .vulnerabilityText2 {
        // padding-right: 200px;
        color: $gray6;
        font-weight: $semiBoldFont;
        // text-align: center;
      }
    }
    .accordionWrapper {
      margin-bottom: 16px;
      .accordionListItem {
        border-radius: 4px;
        overflow: hidden;
        background-color: $white !important;
        opacity: 1 !important;
        & > div {
          opacity: 1;
        }
      }

      .accordionHeader {
        position: relative;
        .headerTitle {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 600px;
          margin-bottom: 4px;
        }
        .headerText {
          color: $gray7;
          max-width: 800px;
        }
      }
      .moreIcon {
        cursor: pointer;
        position: relative;
        left: -8px;
        //float: right;
        // margin-right: 16px;
      }
      .VulnerabilityDetailsWrapper {
        padding: 0 16px;
        .headerWrapper {
          margin-bottom: 16px;
          text-transform: capitalize;
          span {
            color: $gray9;
            background-color: $gray2;
            font-size: $font13;
            border-radius: 4px;
            padding: 0px 8px;
            margin-left: 10px;
          }
        }
        .listItemWrapper {
          padding: 6px 8px;
          cursor: pointer;
          @include transitionForAll($time: 0.3s, $transProp: ease-in);
          &:hover {
            background-color: $white;
            box-shadow: 0px 0px 10px rgba(77, 92, 104, 0.15);
          }
          .moreIcon {
            display: flex;
            cursor: pointer;
            position: relative;
            left: -8px;
            // float: right;
            // margin-right: 16px;
          }
        }
      }
    }
  }
  .vulnerabilityDialogWrapper {
    width: 600px;
    .headerTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 24px;
      border-bottom: 1px solid $gray2;
      & img {
        cursor: pointer;
      }
    }
    .formDetail {
      padding: 24px;
      .inputBlk {
        :global(.MuiOutlinedInput-root) {
          &:global(.Mui-disabled) {
            input,
            textarea {
              background-color: $white;
            }
          }
        }
        :global(.MuiInputBase-multiline) {
          & > textarea {
            margin-top: 24px;
            height: 96px !important;
            max-height: 120px !important;
          }
        }
      }
      .detailDescWrapper {
        .formTitle {
          padding: 0 0 8px 0;
          color: $gray10;
        }
        .desc {
          font-size: $font16;
          color: $gray10;
          line-height: $lineHeight24;
          padding: 6px 16px;
          border-radius: 4px;
          border: 1px solid $gray3;
        }
      }
      .formTitle {
        padding: 8px 0;
      }
      .errorFormTitle {
        padding: 8px 0;
        color: $error_red;
        font-weight: $semiBoldFont;
      }
      .inputBlk {
        & input,
        & textarea {
          // border: none;
          &:focus {
            border: 1px solid $primary_blue;
          }
        }
      }
    }
    .buttonWrapper {
      display: flex;
      justify-content: flex-end;
      gap: 16px;
      padding: 16px 32px;
    }
  }
  .updateVulnerabilityWrapper {
    .title {
      padding: 32px 0;
      .backLink {
        color: $gray7;
        display: flex;
        // line-height: $lineHeight16;
        margin-bottom: 10px;
        cursor: pointer;
        padding: 2px 8px 2px 2px;
        border: 1px solid $gray2;
        border-radius: 2px;
        width: fit-content;
        & img {
          transform: rotate(90deg);
          margin-right: 4px;
        }
      }
    }
    .formDetail {
      max-width: 720px;
      .formTitle {
        margin-bottom: 8px;
      }
      .errorFormTitle {
        padding: 8px 0;
        color: $error_red;
        font-weight: $semiBoldFont;
      }
      .inputBlk {
        & > div {
          background-color: $white;
        }
      }
      .vulnerabilityList {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $white;
        padding: 6px 16px;
        border-radius: 4px;
        margin-bottom: 12px;
        .listTitle,
        .subText {
          white-space: nowrap;
          text-overflow: ellipsis;
          cursor: pointer;
          overflow: hidden;
          width: 300px;
          & span {
            padding-left: 5px;
          }
        }
        img {
          cursor: pointer;
        }
      }
      .addBtn {
        border: 1px solid $gray2;
        width: 100%;
        color: $gray9;
        &:hover,
        &:focus,
        &:active {
          background-color: $white;
        }
      }
      .buttonWrapper {
        margin-top: 24px;
        padding: 16px 0;
        display: flex;
        border-top: 1px solid $gray2;
        justify-content: flex-end;
        gap: 16px;
      }
    }
  }
  .vulnerabilityInstanceWrapper {
    background-color: $white;
    min-height: 100vh;
    padding-bottom: 60px;
    .headerWrapper {
      width: 100%;
      background-color: $gray1;
      .backWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0;
        .backBtn {
          .btnText {
            display: flex;
            padding: 2px 8px 2px 2px;
            color: $gray7;
            border: 1px solid $gray2;
            border-radius: 2px;
            img {
              padding: 4px;
            }
          }
        }
        .closeBtn {
          display: flex;
        }
      }
    }
    .headerBlk {
      padding: 24px 0 32px;
    }
    .instanceListHeader {
      color: $gray6;
      padding-top: 0;
      margin: 0;
      width: 100%;
      text-transform: uppercase;
      padding-bottom: 16px;
      & > div {
        padding: 0 0 0 16px;
      }
    }

    .projectListCardContainer {
      margin-top: 4px;
      padding: 6px 0;
      background-color: $gray1;
      .listCardItem {
        padding: 0;
        margin: 0;
        width: 100%;
        & > div {
          padding: 0 0 0 16px;
        }
        .projectId {
          position: relative;
          display: flex;
          align-items: center;
          label {
            margin: 0;
          }
        }
        .projectName {
          position: relative;
          font-weight: $semiBoldFont;
          display: flex;
          align-items: center;
          img {
            position: absolute;
            width: 20px;
          }
          span {
            display: block;
          }
        }
        .projectRisk {
          position: relative;
          display: flex;
          align-items: center;
          height: 20px;
          align-self: center;
          img {
            position: absolute;
            height: 20px;
          }
        }
        .projectStatus {
          position: relative;
          display: flex;
          align-items: center;
          .statusImg {
            padding-right: 4px;
          }
          .success {
            color: $support_green;
          }
          .pending {
            color: $gray5;
          }
          .discarded {
            color: $support_red;
          }
          .progress {
            color: $primary_blue;
          }
        }
        .viewDetailLink {
          font-size: $font14;
          color: $primary_blue;
          text-decoration: underline;
        }
      }
    }
  }
}
