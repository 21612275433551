@import "variable";

body {
  background-color: $gray1;
  .dialogWrapper {
    width: 600px;

    .headerTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 24px;
      border-bottom: 1px solid $gray2;
      & img {
        cursor: pointer;
      }
    }
    .formDetail {
      padding: 24px;
      .companyLogoButton {
        display: flex;
        padding: 8px 0px 16px 0px;
        & img {
          width: 50px;
          height: 50px;
        }
        .importButtonWrapper {
          position: relative;
          display: inline-block;
          .importButton {
            padding: 5px 16px;
            margin: 4px 0px 0px 24px;
          }
          .inputFile {
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
          }
        }
      }

      .formTitle {
        padding: 8px 0;
      }
      .errorFormTitle {
        padding: 8px 0;
        color: $support_red;
      }
      .inputBlk {
        & input,
        & textarea {
          // border: none;
          &:focus {
            border: 1px solid $primary_blue;
          }
        }
      }
    }
    .buttonWrapper {
      display: flex;
      justify-content: flex-end;
      gap: 16px;
      padding: 16px 32px;
    }
  }
  .clientDashboardWrapper {
    .clientTitleWrapper {
      display: flex;
      justify-content: space-between;
      padding-top: 32px;
      margin-bottom: 20px;
      .clientSearchAndViewWrapper {
        display: flex;
        gap: 44px;
        .viewWrapper {
          display: flex;
          align-items: center;
          gap: 16px;
        }
        .clearClientSearch {
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: underline;
          cursor: pointer;
          color: $primary_blue;
          margin-right: -20px;
        }
      }
    }
    .clientGridCardContainer {
      border: 1px solid $gray2;
      border-radius: 4px;
      background-color: $white;
      padding: 16px;
      .clientName {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      & img {
        margin-bottom: 4px;
        width: 36px;
        height: 36px;
      }
      cursor: pointer;
      @include transitionForAll($time: 0.3s, $transProp: ease-in);
      &:hover {
        box-shadow: 0px 0px 10px rgba(77, 92, 104, 0.15);
      }
    }
  }
  .projectTitleWrapper {
    background-color: $white;
    .projectTitleBlk {
      padding: 20px 0;
      .projectTitle {
        margin-bottom: 16px;
        color: $gray10;
        span {
          color: $gray5;
        }
      }
      .projectHeaderWrapper {
        display: flex;
        .projectHeaderLeft {
          display: flex;
          flex-grow: 8;
          gap: 12px;
          .subTitle {
            color: $gray6;
          }
          & img {
            width: 40px;
            height: 40px;
          }
        }
        .projectHeaderRight {
          display: flex;
          .researchDetails {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 4;
            .researcherDetail {
              line-height: $lineHeight16;
              color: $gray6;
              display: flex;
              align-items: center;
              margin-right: 8px;
              // margin-bottom: 32px;
              & span {
                display: flex;
                align-items: center;
                color: $gray10;
                & img {
                  margin: 0 10px 0 16px;
                }
              }
            }
            .usersAvatar {
              position: relative;
              & > div {
                width: 28px;
                height: 28px;
                font-weight: $regularFont;
                font-size: $font14;
                color: $blue4;
                background-color: $button_bg_blue;
              }
            }
          }
          .projectUsers {
            flex-grow: 3;
            display: flex;
            align-items: center;
            gap: 8px;
            justify-content: flex-end;
            padding-right: 16px;
            .usersDetail {
              line-height: $lineHeight16;
              color: $gray6;
              display: flex;
              align-items: center;
              // margin-bottom: 32px;
              // padding-left: 10px;
              span {
                padding-right: 8px;
              }
              .usersAvatar {
                position: relative;
                & > div {
                  width: 28px;
                  height: 28px;
                  font-weight: $regularFont;
                  font-size: $font14;
                  color: $blue4;
                  background-color: $button_bg_blue;
                }
              }
            }
            .userManager {
              color: $primary_blue;
              display: inline-block;
              span {
                font-size: $font14;
                line-height: $lineHeight24;
                color: $gray9;
              }
            }
          }
          .userManagerWrapper {
            display: flex;
            align-items: center;
            & img {
              height: 24px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .projectDetailPageWrapper {
    padding: 16px 0 24px 0;
    .projectListView {
      .projectListViewTitleWrapper {
        display: flex;
        justify-content: space-between;
        .projectListViewTitle {
          // margin-bottom: 24px;
          & span {
            margin-left: 8px;
            border-radius: 4px;
            padding: 4px 6px;
            background-color: $gray2;
          }
        }
        .clearSearch {
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: underline;
          cursor: pointer;
          color: $primary_blue;
          margin-left: auto;
          margin-right: 20px;
        }
        .projectSearchWrapper {
          display: flex;
          align-items: center;
          gap: 16px;
        }
        .projectSearchWrapper {
          display: flex;
          align-items: center;
          gap: 16px;
        }
      }
    }

    .listCardHeader {
      color: $gray6;
      padding: 0;
      margin: 0;
      width: 100%;
      text-transform: uppercase;
      padding: 4px 0;
      margin: 16px 0 12px 0;
      & > div {
        padding: 0 0 0 16px;
      }
      .projectId {
        position: relative;
        display: flex;
        align-items: center;
        label {
          margin: 0;
        }
        span {
          padding-left: 30px;
          font-size: $font10;
          font-weight: $semiBoldFont;
          color: $gray6;
          line-height: $lineHeight16;
        }
      }
    }
    .listCardFooter {
      margin-bottom: 24px;
    }
    .projectListCardContainer {
      // border: 1px solid $gray2;
      //margin-top: 12px;
      padding: 6px 0;
      border-radius: 4px;
      background-color: $white;
      margin-bottom: 4px;
      .listCardItem {
        padding: 0;
        margin: 0;
        width: 100%;
        & > div {
          padding: 0 0 0 16px;
        }
        .projectId {
          position: relative;
          display: flex;
          align-items: center;
          label {
            margin: 0;
          }
          .checkbox {
            position: absolute;
            padding: 0;
            width: 12px;
            height: 12px;
            color: $gray2;
          }
          & :global(.Mui-checked) {
            color: $primary_blue;
          }
          span {
            padding-left: 30px;
            font-size: $font14;
            font-family: $font_primary;
            line-height: $lineHeight24;
            color: $gray9;
          }
        }
        .projectName {
          position: relative;
          // font-weight: $semiBoldFont;
          display: flex;
          align-items: center;
          img {
            position: absolute;
            width: 20px;
          }
          span {
            display: block;
          }
        }
        .scoreClolorCritical {
          font-size: $font16;
          font-weight: $semiBoldFont;
          color: $csvv_status_critical;
        }
        .scoreClolorHigh {
          font-size: $font16;
          font-weight: $semiBoldFont;
          color: $csvv_status_high;
        }
        .scoreClolorMedium {
          font-size: $font16;
          font-weight: $semiBoldFont;
          color: $csvv_status_medium;
        }
        .scoreClolorLow {
          font-size: $font16;
          font-weight: $semiBoldFont;
          color: $csvv_status_low;
        }
        .scoreClolorNone {
          font-size: $font16;
          font-weight: $semiBoldFont;
          color: $csvv_status_none;
        }
        .scoreNames {
          padding-left: 8px;
        }
        .statusNone {
          color: $csvv_status_none;
          font-weight: $semiBoldFont;
          font-size: $font16;
        }
        .projectStatus {
          position: relative;
          display: flex;
          align-items: center;
          span {
            display: block;
            padding-left: 20px;
            &.success {
              color: green;
            }
            &.pending {
              color: $gray5;
            }
            &.progress {
              color: $primary_blue;
            }
            &.discarded {
              color: $support_red;
            }
            &.none {
              padding: 0;
            }
          }
          img {
            position: absolute;
          }
        }

        .viewDetailLink {
          font-size: $font12;
          color: $primary_blue;
          text-decoration: underline;
          .moreIcon {
            cursor: pointer !important;
            display: flex;
          }
        }
      }
      .disableRow {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
  .clientUserDetail {
    background-color: $white;
    // height: 100vh;
    .userHeaderWrapper {
      background-color: $gray1;
      padding: 8px 0;
      .userHeaderContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .headerLeftBlk {
          .backBtn {
            border: 1px solid $gray2;
            border-radius: 3px;
            padding: 2px 8px 2px 2px;
            color: $gray7;
            display: flex;
            align-items: center;
            img {
              padding: 4px;
            }
          }
        }
      }
    }
    .userDashboardWrapper {
      padding-bottom: 40px;
      .userTitleWrapper {
        display: flex;
        justify-content: space-between;
        padding-top: 32px;
        .userSearchAndViewWrapper {
          display: flex;
        }
      }
      .userListWrapper {
        display: flex;
        justify-content: space-between;
        margin: 32px 0 16px 0;
        font-size: $font13;
        font-weight: $semiBoldFont;
        color: $gray6;
        padding: 0 16px;
        .userListTitle {
          & span {
            margin-left: 8px;
            border-radius: 4px;
            padding: 4px 6px;
            background-color: $gray2;
          }
        }
        .userSearchAndViewWrapper {
          display: flex;
        }
        .clearSearch {
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: underline;
          cursor: pointer;
          color: $primary_blue;
          margin-right: 20px;
        }
      }
      .userListItem {
        padding: 6px 16px;
        background: $gray1;
        margin-bottom: 4px;
        & > div {
          display: flex;
          align-items: center;
          & > div {
            .userName {
              display: flex;
              align-items: center;
              color: $gray10;
              img {
                border-radius: 50%;
                //padding-right: 10px;
                width: 24px;
                height: 24px;
              }
              span {
                white-space: nowrap;
                overflow: hidden;
                margin-left: 10px;
              }
            }
            .userDetail {
              color: $gray7;
              white-space: nowrap;
              overflow: hidden;
              margin-right: 10px;
            }
            .moreIcon {
              float: right;
              cursor: pointer !important;
            }
            .disableIcon {
              float: right;
              cursor: not-allowed !important;
            }
          }
        }
        .userStatus {
          position: relative;
          display: flex;
          align-items: center;

          span {
            display: block;
            padding-left: 20px;
            border-radius: 2px;
            &.success {
              color: $support_green;
              background-color: $success_bg;
              padding: 0 10px 0 30px;
            }
            &.pending {
              color: $support_gold;
              background-color: $gold_bg;
              padding: 0 10px 0 30px;
            }
            &.deactivated {
              color: $support_red;
              background-color: $error_bg;
              padding: 0 10px 0 30px;
            }
          }
          img {
            position: absolute;
            width: 16px;
            height: 16px;
            padding: 0 0 0 10px;
          }
        }
        .disableRow {
          opacity: 0.5;
          cursor: not-allowed;
        }
        .userStatus {
          position: relative;
          display: flex;
          align-items: center;

          span {
            display: block;
            padding-left: 20px;
            border-radius: 2px;
            &.success {
              color: $support_green;
              background-color: $success_bg;
              padding: 0 10px 0 30px;
            }
            &.pending {
              color: $support_gold;
              background-color: $gold_bg;
              padding: 0 10px 0 30px;
            }
          }
          img {
            position: absolute;
            width: 16px;
            height: 16px;
            padding: 0 0 0 10px;
          }
        }
      }
    }
  }
  .NoSearchResult {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
