@import "variable";

body {
  .headerWrapper {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    border-bottom: 1px solid $gray2;
    background-color: $white;
    position: sticky;
    top: 0;
    z-index: 10;
    .headerLeftBlk {
      height: 100%;
      display: flex;
      align-items: center;
      .brandLogo {
        width: 100px;
        height: 24px;
      }
    }
    .headerRightBlk {
      display: flex;
      align-items: center;
      .notificationBlk {
        display: block;
        height: 32px;
        & > a {
          position: relative;
          display: block;
          height: 32px;
          .notificationCount {
            right: 1px;
            top: 1px;
            width: 10px;
            text-align: center;
            position: absolute;
            line-height: $lineHeight12;
            color: $primary_blue;
            font-weight: $semiBoldFont;
            padding: 2px;
            background-color: $gray2;
            border-radius: 50%;
          }
        }
      }
      .profileWrapper {
        button {
          padding: 0;

          .name {
            margin-left: 8px;
            text-transform: capitalize;
          }
          .profilePic {
            width: 28px;
            height: 28px;
            border-radius: 50px;
          }
          .avatar {
            width: 28px;
            height: 28px;
            border-radius: 50px;
            // line-height: $lineHeight20;
            background: $gray4;
            color: $white;
            font-size: $font14;
            font-weight: $mediumFont;
          }
          .caret {
            margin-left: 2px;
            width: 16px;
            position: relative;
            top: 1px;
            transform: rotate(180deg);
          }
          &:hover {
            background: transparent;
          }
        }
        button[aria-controls="menu-list-grow"] .caret {
          transform: none;
        }
      }
      .profileDropdown {
        width: 180px;
        top: 12px !important;
        z-index: 9;
        border-radius: 6px;
        > div {
          box-shadow: none;
        }
        ul {
          position: relative;
          background: $white;
          box-shadow: 0px 4px 9px 1px rgba(143, 146, 151, 0.19);
          border-radius: 6px;
          padding: 0;
          margin-right: 12px;
          border: 1px solid $gray2;
          li {
            padding: 8px 16px;
            .defaultImg,
            .activeImg {
              vertical-align: middle;
              width: 16px;
              height: 16px;
              object-fit: cover;
            }
            .defaultImg {
              display: inline-block;
            }
            .activeImg {
              display: none;
            }
            .menuText {
              color: $gray7;
              font-weight: $semiBoldFont;
              margin-left: 8px;
              vertical-align: middle;
            }
            display: block;
            &:hover,
            &:focus,
            &:active {
              .defaultImg {
                display: none;
              }
              .activeImg {
                display: inline-block;
              }
              .menuText {
                color: $primary_blue;
              }
              border-radius: 6px;
              background: $gray1;
            }
          }
        }
      }
    }
  }
  .dashboardTabWrapper {
    background-color: $white;
    height: 56px;
    border-bottom: 1px solid $gray2;
    position: sticky;
    top: 49px;
    z-index: 9;
    .tabListItem {
      display: flex;
      gap: 4px;
      .tabLink {
        position: relative;
        color: $gray5;
        height: 100%;
        padding: 16px 12px;
        &:hover,
        &.active {
          color: $primary_blue;
          & span {
            position: absolute;
            height: 2px;
            background: $primary_blue;
            border-top-right-radius: 6px;
            border-top-left-radius: 6px;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }
      }
    }
  }
  .formWrapper {
    padding: 24px;
    background: $white;
    border: 1px solid $gray1;
    box-shadow: 0px 8px 20px -3px rgba(87, 96, 117, 0.09);
    border-radius: 4px;
  }
  .validatorWrapper {
    padding: 12px 8px;
    .title {
      color: $gray6;
      padding-bottom: 12px;
    }
    .conditionList {
      display: flex;
      padding-bottom: 6px;
      & > span {
        font-weight: $semiBoldFont;
        padding: 0 4px;
      }
    }
  }
  .snackbar {
    .toastIcon {
      height: 16px;
      width: 16px;
      position: absolute;
      top: 12px;
      left: 12px;
      object-fit: cover;
    }
    .closeIcon {
      height: 16px;
      width: 16px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 12px;
      cursor: pointer;
      object-fit: cover;
    }
    > div {
      min-width: 400px;
      height: 40px;
      padding: 12px;
      background: $white;
      box-sizing: border-box;
      border-radius: 4px;
      position: relative;
      padding-right: 36px;
      display: flex;
      align-items: center;
      p {
        padding-left: 40px;
        @include fontStyle($font14, $semiBoldFont, $lineHeight20);
        .toastMessage {
          font-weight: $semiBoldFont;
          display: block;
        }
      }
      &.info {
        border: 1px solid $support_blue;
        background: $info_bg;
        p {
          color: $support_blue;
        }
      }
      &.success {
        border: 1px solid $support_green;
        background: $success_bg;
        p {
          color: $support_green;
        }
      }
      &.error {
        border: 1px solid $support_red;
        background: $error_bg;
        p {
          color: $support_red;
        }
      }
      &.warning {
        border: 1px solid $support_yellow;
        background: $warning_bg;
        p {
          color: $support_yellow;
        }
      }
    }
  }
  .selectDropdown {
    border-radius: 4px;
    width: 100%;
    height: 36px;
    color: #353941;
    font-size: 14px;
    border: 1px solid $gray3;
    // &:hover,
    &:focus,
    &:active {
      background-color: $blue1;
      border: 1px solid $primary_blue;
    }
    & input {
      border: none;
    }
    & .error {
      border: 1px solid $support_red;
      background: $error_bg;
      p {
        color: $support_red;
      }
    }
    & > fieldset {
      border: none;
    }
    // & > ul {
    //   position: relative;
    // }
    // img {
    //   padding-right: 8px;
    //   cursor: pointer;
    // }
  }
  .noResultFound {
    min-height: 200px;
    img {
      margin-bottom: 12px;
    }
    .noStateSubHeading {
      font-size: $font14;
      font-weight: $regularFont;
      color: $gray5;
      line-height: $lineHeight20;
      span {
        font-weight: $semiBoldFont;
      }
    }
    p {
      margin-top: 16px;
    }
  }
  .confirmationDialog {
    .MuiDialog-paper {
      width: 400px;
      border-radius: 0px;
      position: relative;
      .MuiDialogTitle-root {
        position: relative;
        padding: 0;
        .backgroundImage {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
        .logo {
          position: absolute;
          top: 88px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .dialogBottom {
        padding: 50px 24px 24px;
        .subText {
          text-align: center;
          margin: 16px 0 24px;
        }
        .btnWrapper {
          display: flex;
          justify-content: space-between;
          & button {
            width: 160px;
          }
        }
      }
    }
  }
  .editProfileDialogWrapper {
    width: 600px;
    .headerTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 24px;
      border-bottom: 1px solid $gray2;
      & img {
        cursor: pointer;
      }
    }
    .formDetail {
      padding: 20px;
      .formTitle {
        padding-bottom: 8px;
      }
      .profileWrapper {
        position: relative;
        display: flex;
        align-items: center;
        gap: "48px";
        .profileImg {
          object-fit: cover;
          border-radius: 50%;
          width: 64px;
          height: 64px;
        }
        .inputFile {
          position: relative;
          width: 120px;
          height: 36px;
          left: 18px;
          top: 0;
          opacity: 0;
          cursor: pointer;
        }
        .uploadBtn {
          border-radius: 8px;
          padding: 6px 16px;
          position: absolute;
          left: 85px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .inputBlk {
        & input,
        & textarea {
          // border: none;
          &:focus {
            border: 1px solid $primary_blue;
          }
        }
      }
      .changePasswordWrapper {
        position: relative;
        cursor: pointer;
        .inputGroup {
          position: relative;
          input {
            padding: 6px 44px 6px 16px;
          }
        }
        img {
          position: absolute;
          padding: 10px;
          cursor: pointer;
          right: 0;
          top: 0;
        }
      }
    }
    .editPasswordWrapper {
      padding: 20px;
      .passwordWrapper {
        position: relative;
        .inputGroup {
          position: relative;
          input {
            padding: 6px 44px 6px 16px;
          }
        }
        .showPassword {
          position: absolute;
          right: 8px;
          top: 48%;
          display: flex;
          padding: 4px 6px;
        }
      }
    }
    .buttonWrapper {
      display: flex;
      border-top: 1px solid $gray2;
      justify-content: flex-end;
      gap: 16px;
      padding: 16px 32px;
    }
  }
  .AccountSettingDialogWrapper {
    width: 600px;
    .headerTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 24px;
      border-bottom: 1px solid $gray2;
      & img {
        cursor: pointer;
      }
    }
    .settingTabWrapper {
      display: flex;
      gap: 4px;
      padding-left: 12px;
      border-bottom: 1px solid $gray2;
      .tabLink {
        position: relative;
        color: $gray5;
        height: 100%;
        padding: 16px 12px;
        &:hover,
        &.active {
          color: $primary_blue;
          & span {
            position: absolute;
            height: 2px;
            background: $primary_blue;
            border-top-right-radius: 6px;
            border-top-left-radius: 6px;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }
      }
    }
    .formDetail {
      padding: 24px;
      .formTitle {
        color: $gray10;
        padding-bottom: 8px;
      }
      .formText {
        color: $gray7;
      }
      .fieldWrapper {
        position: relative;
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid $gray2;
        &.last-child {
          padding-bottom: 0;
          margin-bottom: 0;
          border: none;
        }
        .selectDropdown {
          width: 50%;
        }
        .switchBtn {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      .profileWrapper {
        position: relative;
        display: flex;
        align-items: center;
        gap: "48px";
        .profileImg {
          object-fit: cover;
          border-radius: 4px;
          width: 60px;
        }
        .inputFile {
          position: relative;
          width: 120px;
          height: 36px;
          left: 0;
          top: 0;
          opacity: 0;
          cursor: pointer;
        }
        .uploadBtn {
          border-radius: 8px;
          padding: 6px 16px;
          position: absolute;
          left: 110px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .inputBlk {
        & input,
        & textarea {
          // border: none;
          &:focus {
            border: 1px solid $primary_blue;
          }
        }
      }
    }
    .buttonWrapper {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid $gray2;
      gap: 16px;
      padding: 16px 32px;
    }
  }
  .customWysiwygEditorWrapper {
    display: flex;
    flex-direction: column-reverse;
    border: 1px solid $gray2;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    // margin-top: 12px;
    .customWysiwygToolbar {
      // background: #fbfbfb;
      margin-bottom: 0;
      border-radius: 0;
      box-sizing: border-box;
      border: none;
      .rdw-option-wrapper {
        border: none;
        &.rdw-option-active {
          border: none;
          // border: 1px solid #cccc;
        }
      }
      .rdw-link-wrapper {
        .rdw-link-modal {
          height: unset;
          input {
            height: unset;
          }
          .rdw-link-modal-input {
            height: 25px;
          }
          .rdw-link-modal-target-option {
            display: none;
          }
          .rdw-link-modal-buttonsection {
            margin-top: 10px;
          }
        }
      }
    }
    .customWysiwygEditor {
      min-height: 200px;
      .DraftEditor-root {
        padding: 6px 16px;
        max-height: 200px;
        .public-DraftEditorPlaceholder-root {
          color: #9a9ca0;
          position: absolute;
          z-index: 0;
          font-size: 16px;
          opacity: 0.8;
        }
        .DraftEditor-editorContainer {
          .public-DraftStyleDefault-block {
            margin: 0;
            .rdw-link-decorator-wrapper {
              .rdw-link-decorator-icon {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  /* Search Dropdown in Project */
  .dropdown {
    position: relative;
    display: inline-block;
    .dropdown-content {
      position: absolute;
      background-color: $white;
      min-width: 270px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
      padding-bottom: 8px;
      .supportText_semiBold {
        margin: 12px 8px 4px 8px;
      }
      .dropdown-list {
        display: flex;
        align-items: center;
        margin: 8px 0 0 8px;
        .dropdown-project-name {
          color: $primary_blue;
        }
      }
      a {
        color: $gray5;
        padding: 4px 4px;
        text-decoration: none;
        display: block;
      }
      a:hover {
        background-color: $tertiary_gray;
      }
    }
  }

  /* Notification Drawer */
  .notificationDrawer {
    position: relative;
    width: 760px;
    height: 100h;
    .drawerHeader {
      background-color: $gray1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 24px;
      z-index: 99;
      border-bottom: 1px solid $gray2;

      .drawerHeaderTitle {
        color: $gray10;
      }
      & img {
        cursor: pointer;
      }
    }
    .drawerMessage {
      padding: 24px;
      background-color: $white;
      display: flex;
      justify-content: space-between;
      .msgNotification {
        color: $gray5;
        & span {
          color: $primary_blue;
        }
      }
      .clearMsg {
        cursor: pointer;
        color: $primary_blue;
      }
    }
    .drawerBody {
      background-color: $gray1;
      height: 100vh;
      .noStateWrapper {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .outerBlk {
        &.unreadBackground {
          background-color: $gray1;
        }
        &.readBackground {
          background-color: $white;
        }
        .notificationListItem {
          padding: 12px 0;
          margin: 0 24px;
          border-bottom: 1px solid $gray2;
          cursor: pointer;
          .drawerContent {
            display: flex;
            justify-content: space-between;
            .drawerContentLeft {
              display: flex;
            }
            .contentMiddle {
              padding: 0 12px;
              .mesaageContext {
                padding-bottom: 12px;
                color: $gray9;
              }
              .messageTime {
                color: $gray6;
                text-transform: uppercase;
                // margin: 12px 60px;
              }
            }
            .unseenMsgColor {
              background-color: $support_red;
              border-radius: 50%;
              width: 12px;
              height: 12px;
              align-self: center;
            }
            .seenMsgColor {
              background-color: $red_2;
              border-radius: 50%;
              width: 12px;
              height: 12px;
              align-self: center;
            }
          }
        }
      }
    }
  }
  .multipleSelect {
    position: relative;
    .MuiOutlinedInput-root {
      border-radius: 4px;
      padding: 0;
      input {
        padding: 6px 16px !important;
      }
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid $gray3;
        &:active {
          border: 2px solid $primary_blue;
        }
      }
      .MuiChip-root {
        border-radius: 2px;
        border: 1px solid $primary_blue;
        height: 28px;
        background-color: $blue1;
        margin: 6px 0 6px 6px;
        .MuiChip-label {
          color: $primary_blue;
        }
      }
    }
  }
  .fullImageDialogContainer {
    .fullImageHeader {
      background-color: rgba($gray10, 0.7);
      position: relative;
      padding: 40px;
      width: 520px;
      height: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      .closeIcon {
        position: absolute;
        right: 24px;
        top: 24px;
        cursor: pointer;
      }
      .fullImageView {
        max-height: 400px;
      }
    }
    .slick-slider {
      width: 860px;
      height: 400px;
      img {
        width: 860px;
        height: 400px;
        object-fit: cover;
        border: 1px solid $gray2;
        box-sizing: border-box;
        border-radius: 6px;
      }
      .slick-prev {
        left: -50px;
      }
      .slick-next {
        right: -50px;
      }
      .slick-prev,
      .slick-next {
        width: 36px;
        height: 36px;
        &:before {
          font-size: 36px;
        }
      }
    }
  }
  .markdown-link {
    color: #0052d9;
  }
}
