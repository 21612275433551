@import "variable";

body {
  .authSection {
    min-height: 100vh;
    background: $secondary_gray;
    position: relative;
    .bg_right {
      position: absolute;
      height: 24vh;
      right: 24px;
      top: 44px;
    }
    .logoWrapper {
      padding: 84px 0 24px;
    }
    .copyrightWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 24px;
      .copyrightText {
        color: $gray5;
        padding-left: 4px;
      }
    }
    .bg_left {
      position: absolute;
      height: 24vh;
      left: 18px;
      bottom: 0;
    }
    .signInFormWrapper {
      display: flex;
      justify-content: center;
      .signInForm {
        width: 400px;
        max-width: 600px;
        .title {
          margin-bottom: 16px;
        }
        .passwordWrapper {
          position: relative;
          cursor: pointer;
          & :global(.inputGroup) {
            position: relative;
            & input {
              padding: 6px 44px 6px 16px;
            }
          }
          .showPassword {
            position: absolute;
            right: 8px;
            top: 48%;
            display: flex;
            padding: 4px 6px;
          }
          .customLink {
            position: absolute;
            right: 0;
            top: 0;
            font-size: $font14;
            color: $primary_blue;
            z-index: 9;
          }
        }
        .signinButton {
          width: 100%;
          margin-top: 4px;
        }
        .signinSeparatorWrapper {
          width: 100%;
          display: inline-block;
          text-align: center;
          margin: 24px 0;
          .signinSeparator {
            font-weight: $semiBoldFont;
            font-size: $font14;
            line-height: $lineHeight16;
            color: $gray4;
            overflow: hidden;
            &:after,
            &::before {
              content: "";
              background-color: $gray4;
              display: inline-block;
              height: 1px;
              position: relative;
              vertical-align: middle;
              width: 180px;
            }
            &:after {
              left: 16px;
              margin-right: -50%;
            }

            &:before {
              right: 16px;
              margin-left: -50%;
            }
          }
        }
        .externalSigninWrapper {
          border: 1px solid $primary_blue;
          border-radius: 4px;
          .externalSigninLink {
            padding: 6px;
            display: flex;
            justify-content: center;
            font-weight: $semiBoldFont;
            color: $primary_blue;
            & > img {
              margin-right: 8px;
            }
          }
        }
      }
    }
    .passwordRecoveryFormWrapper {
      display: flex;
      justify-content: center;
      .passwordRecoveryForm {
        width: 400px;
        .formTitle {
          margin-bottom: 16px;
        }
        .formText {
          color: $gray7;
          margin-bottom: 24px;
        }
        .submitButton,
        .continueButton {
          width: 100%;
          margin-bottom: 24px;
        }
        .backToSigninLink {
          color: $primary_blue;
        }
      }
    }
    .resetPasswordWrapper {
      display: flex;
      justify-content: center;
      .ResetPasswordForm {
        width: fit-content;
        min-width: 400px;
        .formTitle {
          margin-bottom: 16px;
        }
        .formText {
          color: $gray7;
          margin-bottom: 24px;
        }
        .resetButton {
          width: 220px;
          left: 50%;
          transform: translateX(-50%);
        }
        .resetSigninButton {
          width: 100%;
          margin-bottom: 24px;
        }
        .MuiTooltip-popper {
          .MuiTooltip-tooltip {
            background-color: red;
          }
        }
        .passwordWrapper {
          position: relative;
          cursor: pointer;
          & :global(.inputGroup) {
            position: relative;
            & input {
              padding: 6px 44px 6px 16px;
            }
          }
          .showPassword {
            position: absolute;
            right: 8px;
            top: 48%;
            // background-color: white;
            display: flex;
            padding: 4px 6px;
          }
        }
      }
    }
  }
}
